export default [
  {
    value: "name",
    text: "Project",
    sortable: true,
    filterable: true,
    width: 200,
  },
  {
    value: "exid",
    text: "Project ID",
    sortable: true,
    filterable: true,

    width: 40,
  },
  {
    value: "status",
    text: "Status",
    sortable: true,
    filterable: true,
    width: 120,
    align: "center",
  },

  {
    value: "cameras",
    text: "Cameras",
    sortable: false,
    filterable: true,

    width: 40,
  },
  {
    value: "cameraStatuses",
    text: "Statuses",
    sortable: false,
    filterable: false,
    align: "center",
    width: 40,
  },
  {
    value: "lastDetection",
    text: "Last detection",
    sortable: false,
    filterable: false,
    width: 20,
  },
  {
    value: "relatedAnpr",
    text: "Related Anpr",
    sortable: false,
    filterable: false,
    width: 20,
  },
  {
    value: "motionDetection",
    text: "Motion detection",
    sortable: false,
    filterable: false,
    width: 28,
  },
  {
    value: "exNvr",
    text: "ExNVR",
    sortable: false,
    filterable: false,
    width: 28,
  },
]
